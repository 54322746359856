'use client';

import Emoji from '@/components/commons/emoji';
import LinkButton from '@/components/commons/link-button';
import ArrowCircleRightIcon from '@/components/icons/arrow-circle-right';
import { memo } from 'react';

function FaqFooter() {
  return (
    <footer className="mt-5 w-full rounded-2xl bg-dodger-blue-500 px-6 py-5 lg:flex lg:items-center lg:justify-between">
      <div className="mb-[0.625rem] flex items-center gap-2 lg:mb-0">
        <Emoji name="animatedThinkingFace" width={32} height={32} />
        <p className="font-medium text-base text-white leading-6">Une autre question&nbsp;?</p>
      </div>

      <LinkButton
        href="mailto:contact@lokappy.co"
        target="_blank"
        color="white"
        className="ml-auto flex h-12 w-fit gap-3 lg:ml-0"
      >
        Contactez-nous
        <ArrowCircleRightIcon width={20} height={20} />
      </LinkButton>
    </footer>
  );
}

export default memo(FaqFooter);
