import { Faq } from '@/models/faq';
import { FAQPage, WithContext } from 'schema-dts';

export const getFaqJsonLd = (faq: Faq[]): WithContext<FAQPage> => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faq.map((item) => ({
      '@type': 'Question',
      name: item.attributes.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.attributes.answer,
      },
    })),
    publisher: {
      '@type': 'Person',
      name: 'Lokappy',
      url: 'https://lokappy.co',
    },
  };
};
