'use client';

import Carousel from '@/components/commons/carousel';
import CarouselItem from '@/components/home/features/carousel-item';
import CarouselTitle from '@/components/home/features/carousel-title';
import { HomeFeaturesCarousel } from '@/contents/home-features-carousel';
import { memo, useState } from 'react';

function Features() {
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <section
      id="features"
      className="mt-[3.75rem] pb-[3rem] lg:mx-auto lg:mt-[6.25rem] lg:max-w-[1320px] lg:px-0 lg:pb-[5.9375rem]"
      aria-labelledby="features-title"
    >
      <h2
        id="features-title"
        className="mb-5 whitespace-pre text-center font-bold text-[1.75rem] leading-normal lg:whitespace-normal lg:text-[2.5rem] lg:leading-[1.35]"
      >
        Votre gestion{'\n'}locative facilitée
      </h2>

      <p className="mx-auto mb-[1.875rem] text-center font-normal text-base text-grey-darker leading-6 lg:max-w-[46.6875rem]">
        Nous vous proposons un outil de gestion de biens immobiliers simple, efficace et gratuit. Notre objectif est de
        simplifier votre gestion locative et de vous faire gagner du temps.
      </p>

      <Carousel
        setCurrentSlide={setCurrentSlide}
        items={HomeFeaturesCarousel.map(({ title, content, href }, index) => ({
          title: (
            <CarouselTitle currentSlide={currentSlide} emoji={title.emoji} slideIndex={index}>
              {title.content}
            </CarouselTitle>
          ),
          content: <CarouselItem content={content} />,
          href: href,
        }))}
        carouselClass="h-[27.5rem] lg:h-[31.25rem]"
      />
    </section>
  );
}

export default memo(Features);
