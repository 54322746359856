'use client';

import FaqFooter from '@/components/faq/footer';
import FaqList from '@/components/faq/list';
import type { Faq } from '@/models/faq';
import { getFaqJsonLd } from '@/utils/json-ld/faq';
import { memo } from 'react';

function FaqMain({ faq, children }: { faq: Faq[]; children: React.ReactNode }) {
  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(getFaqJsonLd(faq)),
        }}
      />

      <section className="mx-auto block bg-white pb-28">
        <div className="mx-auto max-w-[63.25rem] p-5 lg:p-0">
          {children}
          <FaqList faq={faq} />
          <FaqFooter />
        </div>
      </section>
    </>
  );
}

export default memo(FaqMain);
