'use client';

import Carousel from '@/components/commons/carousel';
import Emoji from '@/components/commons/emoji';
import LinkButton from '@/components/commons/link-button';
import ArrowCircleRightIcon from '@/components/icons/arrow-circle-right';
import { EmojiName } from '@/models/emoji-name';
import Picture from '@/public/home/fiche-bien.png';
import Image from 'next/image';
import { memo } from 'react';

const features: { id: string; title: string; text: string; emoji: EmojiName }[] = [
  {
    id: 'houses-2',
    title: 'Ajoutez vos biens immobiliers',
    text: 'Renseignez les informations et le loyer et gérez-les de manière simple, intuitive et rapide',
    emoji: 'key',
  },
  {
    id: 'houses-contract',
    title: 'Un état des lieux simplifié',
    text: "Avec l'état des lieux en ligne, l'ensemble des données est synchronisé et centralisé.",
    emoji: 'package',
  },
  {
    id: 'rental-contract',
    title: 'Créez votre bail de location',
    text: 'Puis partagez-le directement à votre locataire pour la signature du bail',
    emoji: 'clipboard',
  },
  {
    id: 'rental-messages',
    title: 'Échangez avec vos locataires',
    text: 'Échangez facilement avec vos locataires et remontez des problèmes',
    emoji: 'screwdriver',
  },
];

function Rental() {
  return (
    <section className="bg-home-block-gradient py-[3.75rem] lg:py-20">
      <div className="grid grid-cols-1 lg:mx-auto lg:max-w-[80rem] lg:grid-cols-2 lg:gap-[1.6875rem] lg:px-0">
        <div className="col-start-2 row-start-1">
          <h2 className="mb-[1.875rem] max-w-[32.875rem] px-5 text-center font-bold text-[1.75rem] leading-[1.35] lg:px-0 lg:text-left lg:text-[2.5rem]">
            Votre gestion locative n&apos;a jamais été aussi simple
          </h2>
          <ul className="hidden grid-cols-2 grid-rows-2 gap-x-8 gap-y-5 lg:grid">
            {features.map((feature) => (
              <li key={feature.id} className="rounded-2xl bg-white p-5 shadow-[0_0_20px_0_rgba(115,108,165,0.10)]">
                <span className="flex flex-col-reverse">
                  <h4 className="mb-3 font-bold text-base">{feature.title}</h4>
                  <Emoji name={feature.emoji} width={32} height={32} className="mb-[0.625rem]" />
                </span>
                <p className="font-normal text-[0.875rem] text-grey-darker leading-normal">{feature.text}</p>
              </li>
            ))}
          </ul>

          <div className="lg:hidden">
            <Carousel
              carouselClass="h-[12.5rem]"
              items={features.map((feature) => ({
                content: (
                  <div key={feature.id} className="rounded-2xl bg-white p-5 shadow-[0_0_20px_0_rgba(115,108,165,0.10)]">
                    <span className="flex flex-col-reverse">
                      <h4 className="mb-3 font-bold text-base leading-[1.625]">{feature.title}</h4>
                      <Emoji name={feature.emoji} width={32} height={32} className="mb-[0.625rem]" />
                    </span>
                    <p className="font-normal text-[0.875rem] text-grey-darker leading-normal">{feature.text}</p>
                  </div>
                ),
              }))}
            />
          </div>

          <LinkButton href="/sign-up" className="mx-auto mt-[1.875rem] w-fit lg:mx-0">
            Gérer mes biens
            <ArrowCircleRightIcon width={24} height={24} />
          </LinkButton>
        </div>
        <div className="col-start-1 row-start-1 mt-16 hidden lg:block">
          <Image src={Picture} alt="" role="presentation" width={677} height={467} />
        </div>
      </div>
    </section>
  );
}

export default memo(Rental);
