import { HomeFeaturesCarouselItem } from '@/models/home-carousel-item';
import Feature1 from '@/public/home/features/feature-1.png';
import Feature2 from '@/public/home/features/feature-2.png';
import Feature3 from '@/public/home/features/feature-3.png';
import Feature4 from '@/public/home/features/feature-4.png';
import Phone1 from '@/public/home/features/phone-1.png';
import Phone2 from '@/public/home/features/phone-2.png';
import Phone3 from '@/public/home/features/phone-3.png';
import Phone4 from '@/public/home/features/phone-4.png';

export const HomeFeaturesCarousel: HomeFeaturesCarouselItem[] = [
  {
    id: 'feature-1',
    title: {
      emoji: 'package',
      content: 'Vos biens',
    },
    href: '#houses',
    content: {
      title: 'Ajoutez un bien\nen quelques clics',
      subtitle: 'Et attribuez-lui un ou des locataires !',
      features: ['Créez des fiches détaillées', 'Localisez votre bien', 'Assignez un locataire à ce bien'],
      pictures: {
        feature: Feature1,
        phone: Phone1,
      },
    },
  },
  {
    id: 'feature-2',
    title: {
      emoji: 'beamingFaceWithSmilingEyes',
      content: 'Vos locataires',
    },
    href: '#tenants',
    content: {
      title: 'Restez en contact\navec vos locataires',
      subtitle: 'Une bonne gestion passe toujours par une bonne communication.',
      features: ['Échangez facilement avec eux', "Soyez informé des réparations qu'ils effectuent"],
      pictures: {
        feature: Feature2,
        phone: Phone2,
      },
    },
  },
  {
    id: 'feature-3',
    title: {
      emoji: 'clipboard',
      content: 'Documents',
    },
    href: '#documents',
    content: {
      title: 'Stockez et générez\ntous les documents\nessentiels',
      subtitle: 'Retrouvez facilement tous vos documents et ceux de vos locataires dans votre coffre-fort sécurisé.',
      sentence: {
        content: 'Données stockées chez un hébergeur agréé.',
        emoji: 'locked',
        link: {
          content: 'En savoir plus',
          url: 'https://neon.tech/docs/security/security-overview',
        },
      },
      pictures: {
        feature: Feature3,
        phone: Phone3,
      },
    },
  },
  {
    id: 'feature-4',
    title: {
      emoji: 'package',
      content: 'État des lieux',
    },
    href: '#contracts',
    content: {
      title: 'Remplissez facilement\nvos états des lieux',
      subtitle: 'Nous avons simplifié au maximum le remplissage de vos états des lieux.',
      features: ['Une expérience fluide et simple', 'Collaborez facilement avec vos locataires'],
      pictures: {
        feature: Feature4,
        phone: Phone4,
      },
    },
  },
];
